@font-face {
  font-family: 'SF Pro Text';
  src: url('../public/fonts/sf-pro-text-regular-webfont.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../public/fonts/sf-pro-text-medium-webfont.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: url('../public/fonts/sf-pro-text-bold-webfont.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
